import React from 'react';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql } from 'gatsby';

import { layout, tagsBox, headingContainer, heading, subheading, list } from './blog.module.scss';
import { IBanner } from '../models/banner.model';
import { IPostCard } from '../models/post.model';
import { ISitePage, ISitePageContext } from '../models/site-page.model';
import { ITag } from '../models/tag.model';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import TagList from '../components/molecules/tag-list';
import PostsList from '../components/organisms/posts-list';

interface IBlogProps {
    readonly data: {
        bannersCollection: {
            banners: IBanner[];
        } | null;
        posts: { edges: { node: IPostCard }[] };
        promotedPost: IPostCard;
        blogSitePage: ISitePage;
        allTag: { edges: { node: ITag }[] };
    };
    readonly pageContext: ISitePageContext & {
        isTagListing?: boolean;
    };
}

const Blog: React.FC<IBlogProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { bannersCollection, blogSitePage, allTag } = data;
    const { paginationKeys, isTagListing, postsAmount } = pageContext;

    const banner = bannersCollection?.banners[0];

    const blogBreadcrumb = getBreadcrumbItem(blogSitePage.context);
    const breadcrumbs = isTagListing
        ? [blogBreadcrumb, getBreadcrumbItem(pageContext, 'name', true)]
        : [blogBreadcrumb];

    const defaultTag: ITag = {
        tagId: null,
        posts: [],
        slug: blogSitePage.context.slug,
        translations: {
            en: {
                slug: blogSitePage.context.translations.en.slug,
                name: 'All',
            },
            pl: {
                slug: blogSitePage.context.translations.pl.slug,
                name: 'Wszystko',
            },
        },
    };
    const tags = allTag.edges
        .filter(({ node }) => node.posts && node.posts.length > 0)
        .map(({ node }) => node);
    const posts = data.posts.edges.map(({ node }) => node);

    return (
        <>
            <SEO title={t('new.title')} />
            <MainLayout
                className={layout}
                bannersData={banner && { banner, titleData: { Tag: 'h1' } }}
                breadcrumbsConfig={{ items: breadcrumbs }}
            >
                {tags.length > 0 && (
                    <TagList
                        className={tagsBox}
                        defaultTag={defaultTag}
                        tags={tags}
                        asLinks={true}
                        isCentered={true}
                    />
                )}
                <div className={headingContainer}>
                    <h2 className={heading}>{t('blog.heading')}</h2>
                    {postsAmount && (
                        <p className={subheading}>
                            {postsAmount} {t('blog.subheading')}
                        </p>
                    )}
                </div>
                <PostsList className={list} paginationKeys={paginationKeys} posts={posts} />
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query ($language: String!, $postIds: [Int!], $placeId: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        bannersCollection(placeId: { eq: $placeId }) {
            ...bannersCollectionFields
        }

        promotedPost: post(isPromoted: { eq: true }) {
            ...postCardPromotedFields
        }

        posts: allPost(
            filter: { articleId: { in: $postIds } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }

        blogSitePage: sitePage(context: { pageKey: { eq: "blog" } }) {
            ...sitePageFields
        }

        allTag {
            edges {
                node {
                    ...tagFields
                }
            }
        }
    }
`;

export default Blog;
